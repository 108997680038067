import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { getYotpoRatings } from '@utils/services';
import REVIEWS_THRESHOLD_TO_BE_SHOWN from '@constants/yotpo';
import StarIcon from './StarIcon.svg';
import EmptyStar from './EmptyStarIcon.svg';
import HalfStar from './HalfStarIcon.svg';
import StarRatingSkeleton from './StarRatingSkeleton';

type StarRatingProps = {
  algoliaRating?: number;
  algoliaReviews?: number;
  productId?: number;
  reviewsThreshold?: number;
  hideReviewsNumber?: boolean;
  isSRP?: boolean;
  onClick?: () => void;
  setHideReviews?: React.Dispatch<React.SetStateAction<boolean>>;
  setReviewsCount?: React.Dispatch<React.SetStateAction<number>>;
  setHideReviewsHeader?: React.Dispatch<React.SetStateAction<boolean>>;
  averageScore?: number;
  totalReviews?: number;
};

function calculateStarPercentage(rating: number) {
  // Ensure rating is between 0 and 5
  const clampedRating = Math.min(5, Math.max(0, rating));

  // Calculate percentage for each star
  const fullStars = Math.floor(clampedRating);
  const remainderPercentage = (clampedRating - fullStars) * 100;

  // Create array of percentages for each star
  const starPercentages = Array(5)
    .fill(0)
    .map((_, index) => {
      if (index < fullStars) {
        return 100;
      }
      if (index === fullStars) {
        return Number(remainderPercentage.toFixed(1));
      }
      return 0;
    });
  return starPercentages;
}

export default function StarRating({
  algoliaRating,
  algoliaReviews,
  productId,
  reviewsThreshold = 0,
  hideReviewsNumber = false,
  isSRP,
  onClick = () => null,
  setHideReviews,
  setReviewsCount,
  setHideReviewsHeader,
  averageScore: avgScore,
  totalReviews: reviewsTotal,
}: StarRatingProps) {
  const [rating, setRating] = useState<number>(avgScore || 0);
  const [reviews, setReviews] = useState<number>(reviewsTotal || 0);
  const [reviewsLoaded, setReviewsLoaded] = useState(false);
  useEffect(() => {
    if (productId) {
      (async () => {
        try {
          let reviewsCount = reviews;
          if (!rating && !reviews) {
            const { averageScore, totalReviews } = await getYotpoRatings(productId);
            setRating(averageScore);
            setReviews(totalReviews);
            reviewsCount = totalReviews;
          }
          if (setReviewsCount) {
            setReviewsCount(reviewsCount);
          }
          if (reviewsCount === 0 && setHideReviews) {
            setHideReviews(true);
          }
          if (reviewsCount < REVIEWS_THRESHOLD_TO_BE_SHOWN && setHideReviewsHeader) {
            setHideReviewsHeader(true);
          }
          setReviewsLoaded(true);
        } catch (error: any) {
          setReviewsLoaded(true);
        }
      })();
    } else {
      setRating(algoliaRating || 0);
      setReviews(algoliaReviews || 0);
      setReviewsLoaded(true);
    }
  }, [
    algoliaRating,
    algoliaReviews,
    productId,
    rating,
    reviews,
    setHideReviews,
    setHideReviewsHeader,
    setReviewsCount,
  ]);

  // eslint-disable-next-line no-nested-ternary
  return !reviewsLoaded ? (
    <StarRatingSkeleton />
  ) : reviews > 0 && reviews >= reviewsThreshold ? (
    <div
      className="flex"
      style={{ width: 'fit-content' }}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          if (onClick) {
            onClick();
          }
        }
      }}
    >
      <div className="flex items-center">
        <div className="flex gap-3.5px">
          {calculateStarPercentage(Number(rating.toFixed(1))).map((value, index) => {
            let star;
            if (value === 100) {
              star = StarIcon;
            } else if (value >= 30) {
              star = HalfStar;
            } else {
              star = EmptyStar;
            }
            return <Image key={`item-${index.toString()}`} src={star} alt="star" />;
          })}
        </div>
        <span className="text-rp-navy ml-2 font-rp-basetica text-base leading-18px tracking-normal">
          {rating.toFixed(1)}
        </span>
      </div>

      {reviews && !hideReviewsNumber && !isSRP ? (
        <>
          <div className="border-r border-black mr-3 h-11px place-self-center ml-2.5" />
          <span className="text-rp-navy font-rp-basetica text-base leading-18px tracking-normal">
            {reviews} Reviews
          </span>
        </>
      ) : null}

      {reviews && !hideReviewsNumber && isSRP ? (
        <span className="text-rp-navy font-rp-basetica text-base leading-18px tracking-normal ml-1">
          ({reviews})
        </span>
      ) : null}
    </div>
  ) : (
    <div className="h-18px" />
  );
}
